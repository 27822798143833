import { createApp } from 'vue'
import App from './App.vue'
import store from "./store/index.js";
import ras from "./plugins/ras-kit";
import BootstrapVueNext from 'bootstrap-vue-next';
import VueSmoothScroll from 'vue3-smooth-scroll';
import VueSplide from '@splidejs/vue-splide';
import VueMatomo from 'vue-matomo'
import VueLazyload from "vue3-lazyload";
import CanvasJSChart from '@canvasjs/charts';
//import VueCookie from 'vue-cookies'
import axios from 'axios';

import { createWebHistory, createRouter } from "vue-router";

import LayoutDefault from '@/layout/LayoutDefault.vue';
import LayoutArticle from '@/layout/LayoutArticle.vue';
import LayoutSection from '@/layout/LayoutSection.vue';

import Home from '@/views/home.vue';
import Ricerca from '@/views/ricerca.vue';
import NotFound from '@/views/notFound.vue';

import staticRedirects from '@/router/staticRedirects.js';

const app = createApp(App);
app.use(store)
app.use(ras)
app.use(CanvasJSChart)
app.use(BootstrapVueNext)
app.use(VueSplide)
app.use(VueLazyload)
app.config.isCustomElement = tag => tag.startsWith('CanvasJSChart');
app.use(VueMatomo, {
   // Configure your matomo server and site by providing
   host: 'https://ingestion.webanalytics.italia.it',
   siteId: process.env.VUE_APP_MATOMA_APP_ID,
})
app.use(VueSmoothScroll, {
   duration: 400,
   offset: -75,
   updateHistory: false,
})


//const sezioneIDs = ['01', '10', '04', '07', '08', '12'];
function slugifyPath(str) {
   return str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

function getRouteType(tipoContenuto) {
   tipoContenuto = tipoContenuto !== undefined ? tipoContenuto.toLowerCase() : '';
   if (tipoContenuto.toLowerCase() === '03' || tipoContenuto.toLowerCase() === '05' || tipoContenuto.toLowerCase() === '06') {
      return import('@/views/post.vue');
   }
   else if (tipoContenuto.toLowerCase() === '10' || tipoContenuto.toLowerCase() === '11') {
      return import('@/views/post.vue');
   }
   else if (tipoContenuto.toLowerCase() === '01') {
      return import('@/views/sezione.vue');
   }
   else if (tipoContenuto.toLowerCase() === '07' || tipoContenuto.toLowerCase() === '08' || tipoContenuto.toLowerCase() === '04') {
      return import('@/views/lista.vue');
   }
   else if (tipoContenuto.toLowerCase() === '15') {
      return import('@/views/rubrica.vue');
   }
   else if (tipoContenuto.toLowerCase() === '09') {
      return import('@/views/post.vue');
   }
   else {
      return import('@/views/home.vue');
   }
}

function getRouteLayout(tipoContenuto) {

   tipoContenuto = tipoContenuto !== undefined ? tipoContenuto.toLowerCase() : '';

   if (tipoContenuto.toLowerCase() === '03' || tipoContenuto.toLowerCase() === '05' || tipoContenuto.toLowerCase() === '06') {
      return LayoutArticle;
   }
   else if (tipoContenuto.toLowerCase() === '10' || tipoContenuto.toLowerCase() === '11') {
      return LayoutArticle;
   }
   else if (tipoContenuto.toLowerCase() === '01') {
      return LayoutSection;
   }
   else if (tipoContenuto.toLowerCase() === '07' || tipoContenuto.toLowerCase() === '08' || tipoContenuto.toLowerCase() === '04') {
      return LayoutDefault;
   }
   else {
      return LayoutDefault;
   }
}

function namedLink(str) {
   return str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[^A-Za-z0-9]/g, '').toLowerCase();
}

function setupRouter(response) {
   let addedRoute = [];

   for (let menuList of response) {
      //Primo livello
      if (menuList.tipo !== 'ESTERNO' && menuList.attivo) {
         if (menuList.idFonteDati) {
            let menuPathUrl = menuList.linkUrl;
            let nameLink = (menuPathUrl.lastIndexOf('/') > -1 ? namedLink(menuPathUrl.substring(menuPathUrl.lastIndexOf('/'))) : namedLink(menuPathUrl))

            addedRoute.push({
               name: nameLink,
               path: menuPathUrl,
               component: () => getRouteType(menuList.modello),
               props: {
                  id: menuList.idFonteDati,
                  nome: menuList.nome,
                  tipo: menuList.tipo,
                  tipoContenuto: menuList.tipoContenuto,
                  filter: menuList.tipo === 'LISTA_RACCOLTE' || menuList.tipo === 'LISTA_TIPO_CONTENUTO' || menuList.tipo === 'LISTA_TIPO_CONTENUTO_FILTRATO' ? menuList.filtriList : null,
               },
               meta: {
                  breadcrumb: menuList.nome,
                  parents: [],
                  layout: getRouteLayout(menuList.modello),
                  tipoContenuto: menuList.tipo !== 'LISTA_RACCOLTE' || menuList.tipo !== 'LISTA_TIPO_CONTENUTO' || menuList.tipo !== 'LISTA_TIPO_CONTENUTO_FILTRATO' ? menuList.tipoContenuto : menuList.descrizione,
                  filter: menuList.tipo === 'LISTA_RACCOLTE' || menuList.tipo === 'LISTA_TIPO_CONTENUTO' || menuList.tipo === 'LISTA_TIPO_CONTENUTO_FILTRATO' ? menuList.filtriList : null,
               },
            })

            if (menuList.modello === '01' || menuList.modello === '10' || menuList.modello === '04' || menuList.modello === '07' || menuList.modello === '08' || menuList.modello === '12' || menuList.modello === '15') {
               addedRoute.push(
                  {
                     name: nameLink + 'Articolo',
                     path: menuList.linkUrl + '/:tipoContenuto/:slug',
                     params: true,
                     props: true,
                     component: () => import('@/views/post.vue'),
                     meta: {
                        breadcrumb: menuList.nome,
                        parents: [{ name: menuList.nome, url: menuList.linkUrl }],
                        layout: LayoutArticle,
                     }
                  }
               )
            }

            /* Secondo livello */
            if (menuList.menuItemList !== undefined && menuList.menuItemList !== null) {
               for (let secondomenuList of menuList.menuItemList) {
                  let secondPathUrl = secondomenuList.linkUrl;
                  let secondNameLink = (secondPathUrl.lastIndexOf('/') > -1 ? namedLink(secondPathUrl.substring(secondPathUrl.lastIndexOf('/'))) : namedLink(secondPathUrl))
                  //let secondNameArticle = secondomenuList.descrizione;
                  secondomenuList.linkUrl = slugifyPath(secondomenuList.linkUrl);

                  if (secondomenuList.tipo !== 'ESTERNO' && secondomenuList.attivo) {
                     //console.log(secondomenuList.linkUrl)
                     addedRoute.push({
                        name: secondNameLink,
                        path: secondomenuList.linkUrl,
                        props: {
                           id: secondomenuList.idFonteDati,
                           nome: secondomenuList.nome,
                           tipo: secondomenuList.tipo,
                           tipoContenuto: secondomenuList.tipo !== 'LISTA_RACCOLTE' && secondomenuList.tipo !== 'LISTA_TIPO_CONTENUTO' && secondomenuList.tipo !== 'LISTA_TIPO_CONTENUTO_FILTRATO' ? secondomenuList.tipoContenuto : secondomenuList.descrizione,
                           filter: secondomenuList.tipo === 'LISTA_RACCOLTE' || secondomenuList.tipo === 'LISTA_TIPO_CONTENUTO' || secondomenuList.tipo === 'LISTA_TIPO_CONTENUTO_FILTRATO' ? secondomenuList.filtriList : null,
                        },
                        params: true,
                        component: () => getRouteType(secondomenuList.modello),
                        meta: {
                           breadcrumb: secondomenuList.nome,
                           parents: [{ name: menuList.nome, url: menuList.linkUrl }],
                           layout: getRouteLayout(secondomenuList.modello),
                           filter: secondomenuList.tipo === 'LISTA_RACCOLTE' || secondomenuList.tipo === 'LISTA_TIPO_CONTENUTO' || secondomenuList.tipo === 'LISTA_TIPO_CONTENUTO_FILTRATO' ? secondomenuList.filtriList : null,
                        },
                     })


                     if (secondomenuList.modello === '01' || secondomenuList.modello === '10' || secondomenuList.modello === '04' || secondomenuList.modello === '07' || secondomenuList.modello === '08' || secondomenuList.modello === '12') {
                        // Se menu è un sezione o lista
                        addedRoute.push(
                           {
                              name: secondNameLink + 'Articolo',
                              path: secondomenuList.linkUrl + '/:tipoContenuto/:slug',
                              params: true,
                              props: true,
                              component: () => import('@/views/post.vue'),
                              meta: {
                                 breadcrumb: secondNameLink.nome,
                                 parents: [{ name: menuList.nome, url: menuList.linkUrl }, { name: secondomenuList.nome, url: secondomenuList.linkUrl }],
                                 layout: LayoutArticle,
                              }
                           }
                        )
                     }
                  } /* End secondo livello if Esterno */


                  /* Third livello */
                  if (secondomenuList.menuItemList !== undefined && secondomenuList.menuItemList !== null) {
                     for (let terzomenuList of secondomenuList.menuItemList) {
                        let thirdPathUrl = terzomenuList.linkUrl;
                        let thirdNameLink = (thirdPathUrl.lastIndexOf('/') > -1 ? namedLink(thirdPathUrl.substring(thirdPathUrl.lastIndexOf('/'))) : namedLink(thirdPathUrl))
                        terzomenuList.linkUrl = slugifyPath(terzomenuList.linkUrl);
                        if (terzomenuList.tipo !== 'ESTERNO' && terzomenuList.attivo) {
                           // console.log(terzomenuList.linkUrl)
                           addedRoute.push({
                              name: thirdNameLink,
                              path: terzomenuList.linkUrl,
                              params: true,
                              props: {
                                 id: terzomenuList.idFonteDati,
                                 nome: terzomenuList.nome,
                                 tipo: terzomenuList.tipo,
                                 tipoContenuto: terzomenuList.tipo !== 'LISTA_RACCOLTE' && terzomenuList.tipo !== 'LISTA_TIPO_CONTENUTO' && terzomenuList.tipo !== 'LISTA_TIPO_CONTENUTO_FILTRATO' ? terzomenuList.tipoContenuto : terzomenuList.descrizione,
                                 filter: terzomenuList.tipo === 'LISTA_RACCOLTE' || terzomenuList.tipo === 'LISTA_TIPO_CONTENUTO' || terzomenuList.tipo === 'LISTA_TIPO_CONTENUTO_FILTRATO' ? terzomenuList.filtriList : null,
                              },
                              component: () => getRouteType(terzomenuList.modello),
                              meta: {
                                 breadcrumb: terzomenuList.nome,
                                 parents: [{ name: menuList.nome, url: menuList.linkUrl }, { name: secondomenuList.nome, url: secondomenuList.linkUrl }],
                                 layout: getRouteLayout(terzomenuList.modello),
                                 filter: terzomenuList.tipo === 'LISTA_RACCOLTE' || terzomenuList.tipo === 'LISTA_TIPO_CONTENUTO' || terzomenuList.tipo === 'LISTA_TIPO_CONTENUTO_FILTRATO' ? terzomenuList.filtriList : null,
                              },
                           })

                           // Se menu è un sezione o lista
                           if (terzomenuList.modello == '01' || terzomenuList.modello === '10' || terzomenuList.modello === '04' || terzomenuList.modello === '07' || terzomenuList.modello === '08' || terzomenuList.modello === '12') {
                              addedRoute.push(
                                 {
                                    name: thirdNameLink + 'Articolo',
                                    path: terzomenuList.linkUrl + '/:tipoContenuto/:slug',
                                    params: true,
                                    props: true,
                                    component: () => import('@/views/post.vue'),
                                    meta: {
                                       breadcrumb: terzomenuList.nome,
                                       parents: [{ name: menuList.nome, url: menuList.linkUrl }, { name: secondomenuList.nome, url: secondomenuList.linkUrl }, { name: terzomenuList.nome, url: terzomenuList.linkUrl }],
                                       layout: LayoutArticle,
                                    }
                                 }
                              )
                           }

                        } /* End third livello if Esterno */

                        /* Quarto livello */
                        if (terzomenuList.menuItemList !== undefined && terzomenuList.menuItemList !== null) {
                           for (let quartomenuList of terzomenuList.menuItemList) {
                              let quartoPathUrl = quartomenuList.linkUrl;
                              let quartoNameLink = (quartoPathUrl.lastIndexOf('/') > -1 ? namedLink(quartoPathUrl.substring(quartoPathUrl.lastIndexOf('/'))) : namedLink(quartoPathUrl))
                              quartomenuList.linkUrl = slugifyPath(quartomenuList.linkUrl);
                              //console.log(quartomenuList.nome)
                              if (quartomenuList.tipo !== 'ESTERNO' && quartomenuList.attivo) {
                                 //console.log('4: ' + quartomenuList.linkUrl)
                                 //console.log(quartomenuList.modello)
                                 //console.log(quartomenuList.tipoContenuto)
                                 //console.log('linkUrl ' + quartomenuList.linkUrl)
                                 //console.log(getRoutePathURL(quartomenuList))
                                 //console.log('***********************************')

                                 addedRoute.push({
                                    name: quartoNameLink,
                                    path: quartomenuList.linkUrl,
                                    params: true,
                                    props: {
                                       id: quartomenuList.idFonteDati,
                                       nome: quartomenuList.nome,
                                       tipo: quartomenuList.tipo,
                                       tipoContenuto: quartomenuList.tipo !== 'LISTA_RACCOLTE' ? quartomenuList.tipoContenuto : quartomenuList.descrizione,
                                       filter: quartomenuList.tipo === 'LISTA_RACCOLTE' || quartomenuList.tipo === 'LISTA_TIPO_CONTENUTO' || quartomenuList.tipo === 'LISTA_TIPO_CONTENUTO_FILTRATO' ? quartomenuList.filtriList : null,
                                    },
                                    component: () => getRouteType(quartomenuList.modello),
                                    meta: {
                                       breadcrumb: quartomenuList.nome,
                                       parents: [{ name: menuList.nome, url: menuList.linkUrl }, { name: secondomenuList.nome, url: secondomenuList.linkUrl }, { name: terzomenuList.nome, url: terzomenuList.linkUrl }],
                                       layout: getRouteLayout(quartomenuList.modello),
                                       filter: quartomenuList.tipo === 'LISTA_RACCOLTE' || quartomenuList.tipo === 'LISTA_TIPO_CONTENUTO' || quartomenuList.tipo === 'LISTA_TIPO_CONTENUTO_FILTRATO' ? quartomenuList.filtriList : null,
                                    },
                                 })

                                 // Se menu è un sezione o lista
                                 if (quartomenuList.modello == '01' || quartomenuList.modello === '10' || quartomenuList.modello === '04' || quartomenuList.modello === '07' || quartomenuList.modello === '08' || quartomenuList.modello === '12') {
                                    addedRoute.push(
                                       {
                                          name: quartoNameLink + 'Articolo',
                                          path: quartomenuList.linkUrl + '/:tipoContenuto/:slug',
                                          params: true,
                                          props: true,
                                          component: () => import('@/views/post.vue'),
                                          meta: {
                                             breadcrumb: quartomenuList.nome,
                                             parents: [{ name: menuList.nome, url: menuList.linkUrl }, { name: secondomenuList.nome, url: secondomenuList.linkUrl }, { name: terzomenuList.nome, url: terzomenuList.linkUrl }, { name: quartomenuList.nome, url: quartomenuList.linkUrl }],
                                             layout: LayoutArticle,
                                          }
                                       }
                                    )
                                 }
                              } /* End quarto livello if Esterno */

                              /* Quinto livello */
                              if (quartomenuList.menuItemList !== undefined && quartomenuList.menuItemList !== null) {
                                 for (let quintomenuList of quartomenuList.menuItemList) {
                                    let quintoPathUrl = quintomenuList.linkUrl;
                                    let quintoNameLink = (quintoPathUrl.lastIndexOf('/') > -1 ? namedLink(quintoPathUrl.substring(quintoPathUrl.lastIndexOf('/'))) : namedLink(quintoPathUrl))
                                    quintomenuList.linkUrl = slugifyPath(quintomenuList.linkUrl);

                                    if (quintomenuList.tipo !== 'ESTERNO' && quintomenuList.attivo) {
                                       //console.log('5: ' + quintomenuList.linkUrl)
                                       //console.log(quartomenuList.modello)
                                       //console.log(quartomenuList.tipoContenuto)
                                       //console.log('linkUrl ' + quintomenuList.linkUrl)
                                       addedRoute.push({
                                          name: quintoNameLink,
                                          path: quintomenuList.linkUrl,
                                          props: {
                                             id: quintomenuList.idFonteDati,
                                             nome: quintomenuList.nome,
                                             tipo: quintomenuList.tipo,
                                             tipoContenuto: quintomenuList.tipo !== 'LISTA_RACCOLTE' ? quintomenuList.tipoContenuto : quintomenuList.descrizione,
                                             filter: quintomenuList.tipo === 'LISTA_RACCOLTE' || quintomenuList.tipo === 'LISTA_TIPO_CONTENUTO' || quintomenuList.tipo === 'LISTA_TIPO_CONTENUTO_FILTRATO' ? quintomenuList.filtriList : null,
                                          },
                                          component: () => getRouteType(quintomenuList.modello),
                                          meta: {
                                             breadcrumb: quintomenuList.nome,
                                             parents: [{ name: menuList.nome, url: menuList.linkUrl }, { name: secondomenuList.nome, url: secondomenuList.linkUrl }, { name: terzomenuList.nome, url: terzomenuList.linkUrl }, { name: quartomenuList.nome, url: quartomenuList.linkUrl }],
                                             layout: getRouteLayout(quintomenuList.modello),
                                             filter: quintomenuList.tipo === 'LISTA_RACCOLTE' || quintomenuList.tipo === 'LISTA_TIPO_CONTENUTO' || quintomenuList.tipo === 'LISTA_TIPO_CONTENUTO_FILTRATO' ? quintomenuList.filtriList : null,
                                          },
                                       })

                                       // Se menu è un sezione o lista
                                       if (quintomenuList.modello == '01' || quintomenuList.modello === '10' || quintomenuList.modello === '04' || quintomenuList.modello === '07' || quintomenuList.modello === '08' || quintomenuList.modello === '12') {
                                          addedRoute.push(
                                             {
                                                name: quintoNameLink + 'Articolo',
                                                path: quintomenuList.linkUrl + '/:tipoContenuto/:slug',
                                                params: true,
                                                props: true,
                                                component: () => import('@/views/post.vue'),
                                                meta: {
                                                   breadcrumb: quintomenuList.nome,
                                                   parents: [{ name: menuList.nome, url: menuList.linkUrl }, { name: secondomenuList.nome, url: secondomenuList.linkUrl }, { name: terzomenuList.nome, url: terzomenuList.linkUrl }, { name: quartomenuList.nome, url: quartomenuList.linkUrl }, { name: quintomenuList.nome, url: quintomenuList.linkUrl }],
                                                   layout: LayoutArticle,
                                                }
                                             }
                                          )
                                       }
                                    } /* End quinto livello if Esterno */


                                    /* Sesto livello */
                                    if (quintomenuList.menuItemList !== undefined && quintomenuList.menuItemList !== null) {
                                       for (let sestomenuList of quintomenuList.menuItemList) {
                                          let sestoPathUrl = sestomenuList.linkUrl;
                                          let sestoNameLink = (sestoPathUrl.lastIndexOf('/') > -1 ? namedLink(sestoPathUrl.substring(sestoPathUrl.lastIndexOf('/'))) : namedLink(sestoPathUrl))
                                          sestomenuList.linkUrl = slugifyPath(sestomenuList.linkUrl);

                                          if (sestomenuList.tipo !== 'ESTERNO' && sestomenuList.attivo) {
                                             //console.log('5: ' +sestomenuList.linkUrl)
                                             addedRoute.push({
                                                name: sestoNameLink,
                                                path: sestomenuList.linkUrl,
                                                props: {
                                                   id: sestomenuList.idFonteDati,
                                                   nome: sestomenuList.nome,
                                                   tipo: sestomenuList.tipo,
                                                   tipoContenuto: sestomenuList.tipo !== 'LISTA_RACCOLTE' ? sestomenuList.tipoContenuto : sestomenuList.descrizione,
                                                   filter: sestomenuList.tipo === 'LISTA_RACCOLTE' || sestomenuList.tipo === 'LISTA_TIPO_CONTENUTO' || sestomenuList.tipo === 'LISTA_TIPO_CONTENUTO_FILTRATO' ? sestomenuList.filtriList : null,
                                                },
                                                component: () => getRouteType(quintomenuList.modello),
                                                meta: {
                                                   breadcrumb: sestomenuList.nome,
                                                   parents: [{ name: menuList.nome, url: menuList.linkUrl }, { name: secondomenuList.nome, url: secondomenuList.linkUrl }, { name: terzomenuList.nome, url: terzomenuList.linkUrl }, { name: quartomenuList.nome, url: quartomenuList.linkUrl }, { name: quintomenuList.nome, url: quintomenuList.linkUrl },],
                                                   layout: getRouteLayout(sestomenuList.modello),
                                                   filter: sestomenuList.tipo === 'LISTA_RACCOLTE' || sestomenuList.tipo === 'LISTA_TIPO_CONTENUTO' || sestomenuList.tipo === 'LISTA_TIPO_CONTENUTO_FILTRATO' ? sestomenuList.filtriList : null,
                                                },
                                             })

                                             // Se menu è un sezione o lista
                                             if (sestomenuList.modello == '01' || sestomenuList.modello === '10' || sestomenuList.modello === '04' || sestomenuList.modello === '07' || sestomenuList.modello === '08' || sestomenuList.modello === '12') {
                                                addedRoute.push(
                                                   {
                                                      name: sestoNameLink + 'Articolo',
                                                      path: sestomenuList.linkUrl + '/:tipoContenuto/:slug',
                                                      params: true,
                                                      props: true,
                                                      component: () => import('@/views/post.vue'),
                                                      meta: {
                                                         breadcrumb: sestomenuList.nome,
                                                         parents: [{ name: menuList.nome, url: menuList.linkUrl }, { name: secondomenuList.nome, url: secondomenuList.linkUrl }, { name: terzomenuList.nome, url: terzomenuList.linkUrl }, { name: quartomenuList.nome, url: quartomenuList.linkUrl }, { name: quintomenuList.nome, url: quintomenuList.linkUrl }, { name: sestomenuList.nome, url: sestomenuList.linkUrl }],
                                                         layout: LayoutArticle,
                                                      }
                                                   }
                                                )
                                             }
                                          } /* End sesto livello if Esterno */

                                          /* Settimo livello */
                                          if (sestomenuList.menuItemList !== undefined && sestomenuList.menuItemList !== null) {
                                             for (let settimomenuList of sestomenuList.menuItemList) {
                                                let settimoPathUrl = settimomenuList.linkUrl;
                                                let settimoNameLink = (settimoPathUrl.lastIndexOf('/') > -1 ? namedLink(settimoPathUrl.substring(settimoPathUrl.lastIndexOf('/'))) : namedLink(settimoPathUrl))
                                                settimomenuList.linkUrl = slugifyPath(settimomenuList.linkUrl);

                                                if (settimomenuList.tipo !== 'ESTERNO' && settimomenuList.attivo) {
                                                   //console.log('5: ' +settimomenuList.linkUrl)
                                                   addedRoute.push({
                                                      name: settimoNameLink,
                                                      path: settimomenuList.linkUrl,
                                                      props: {
                                                         id: settimomenuList.idFonteDati,
                                                         nome: settimomenuList.nome,
                                                         tipo: settimomenuList.tipo,
                                                         tipoContenuto: settimomenuList.tipo !== 'LISTA_RACCOLTE' ? settimomenuList.tipoContenuto : settimomenuList.descrizione,
                                                         filter: settimomenuList.tipo === 'LISTA_RACCOLTE' || settimomenuList.tipo === 'LISTA_TIPO_CONTENUTO' || settimomenuList.tipo === 'LISTA_TIPO_CONTENUTO_FILTRATO' ? settimomenuList.filtriList : null,
                                                      },
                                                      component: () => getRouteType(settimomenuList.modello),
                                                      meta: {
                                                         breadcrumb: settimomenuList.nome,
                                                         parents: [{ name: menuList.nome, url: menuList.linkUrl }, { name: secondomenuList.nome, url: secondomenuList.linkUrl }, { name: terzomenuList.nome, url: terzomenuList.linkUrl }, { name: quartomenuList.nome, url: quartomenuList.linkUrl }, { name: quintomenuList.nome, url: quintomenuList.linkUrl }, { name: sestomenuList.nome, url: sestomenuList.linkUrl }],
                                                         layout: getRouteLayout(settimomenuList.modello),
                                                         filter: settimomenuList.tipo === 'LISTA_RACCOLTE' || settimomenuList.tipo === 'LISTA_TIPO_CONTENUTO' || settimomenuList.tipo === 'LISTA_TIPO_CONTENUTO_FILTRATO' ? settimomenuList.filtriList : null,
                                                      },
                                                   })

                                                   // Se menu è un sezione o lista
                                                   if (settimomenuList.modello == '01' || settimomenuList.modello === '10' || settimomenuList.modello === '04' || settimomenuList.modello === '07' || settimomenuList.modello === '08' || settimomenuList.modello === '12') {
                                                      addedRoute.push(
                                                         {
                                                            name: settimoNameLink + 'Articolo',
                                                            path: settimomenuList.linkUrl + '/:tipoContenuto/:slug',
                                                            params: true,
                                                            props: true,
                                                            component: () => import('@/views/post.vue'),
                                                            meta: {
                                                               breadcrumb: settimomenuList.nome,
                                                               parents: [{ name: menuList.nome, url: menuList.linkUrl }, { name: secondomenuList.nome, url: secondomenuList.linkUrl }, { name: terzomenuList.nome, url: terzomenuList.linkUrl }, { name: quartomenuList.nome, url: quartomenuList.linkUrl }, { name: quintomenuList.nome, url: quintomenuList.linkUrl }, { name: sestomenuList.nome, url: sestomenuList.linkUrl }, { name: settimomenuList.nome, url: settimomenuList.linkUrl }],
                                                               layout: LayoutArticle,
                                                            }
                                                         }
                                                      )
                                                   }
                                                } /* End settimo livello if Esterno */

                                             } /* End FOR LOOP settimo livello */
                                          } /* End IF settimo livello esiste */

                                       } /* End FOR LOOP sesto livello */
                                    } /* End IF sesto livello esiste */

                                 } /* End FOR LOOP quinto livello */
                              } /* End IF quinto livello esiste */

                           } /* End FOR LOOP quarto livello */
                        } /* End IF quarto livello esiste */

                     } /* End FOR LOOP third livello */
                  } /* End IF third livello esiste */

               } /* End FOR LOOP secondo livello */
            } /* End IF secondo livello esiste */

         } /* End primo livello */
         else {
            if (menuList.modello !== undefined) {
               let menuPathUrl = menuList.linkUrl;
               let nameLink = (menuPathUrl.lastIndexOf('/') > -1 ? namedLink(menuPathUrl.substring(menuPathUrl.lastIndexOf('/'))) : namedLink(menuPathUrl))

               addedRoute.push({
                  name: nameLink,
                  path: menuPathUrl,
                  component: () => getRouteType(menuList.modello),
                  props: {
                     id: menuList.idFonteDati,
                     nome: menuList.nome,
                     tipo: menuList.tipo,
                     tipoContenuto: menuList.tipoContenuto,
                     filter: menuList.tipo === 'LISTA_RACCOLTE' || menuList.tipo === 'LISTA_TIPO_CONTENUTO' || menuList.tipo === 'LISTA_TIPO_CONTENUTO_FILTRATO' ? menuList.filtriList : null,
                  },
                  meta: {
                     breadcrumb: menuList.nome,
                     parents: [],
                     layout: getRouteLayout(menuList.modello),
                     tipoContenuto: menuList.tipo !== 'LISTA_RACCOLTE' || menuList.tipo !== 'LISTA_TIPO_CONTENUTO' || menuList.tipo !== 'LISTA_TIPO_CONTENUTO_FILTRATO' ? menuList.tipoContenuto : menuList.descrizione,
                     filter: menuList.tipo === 'LISTA_RACCOLTE' || menuList.tipo === 'LISTA_TIPO_CONTENUTO' || menuList.tipo === 'LISTA_TIPO_CONTENUTO_FILTRATO' ? menuList.filtriList : null,
                  },
               })

               if (menuList.modello === '01' || menuList.modello === '10' || menuList.modello === '04' || menuList.modello === '07' || menuList.modello === '08' || menuList.modello === '12' || menuList.modello === '15') {
                  addedRoute.push(
                     {
                        name: nameLink + 'Articolo',
                        path: menuList.linkUrl + '/:tipoContenuto/:slug',
                        params: true,
                        props: true,
                        component: () => import('@/views/post.vue'),
                        meta: {
                           breadcrumb: menuList.nome,
                           parents: [{ name: menuList.nome, url: menuList.linkUrl }],
                           layout: LayoutArticle,
                        }
                     }
                  )
               }

            }
         }
      }

   }
   return addedRoute;
}

await axios.get(process.env.VUE_APP_HP_LAYOUT).then((response) => {
   let routes = [
      {
         path: "/",
         name: "Home",
         component: Home,
         meta: {
            breadcrumb: 'home',
            type: "page",
            layout: LayoutDefault,
         },

      }
   ];

   let routesMainMenu = setupRouter(response.data[0].menuList[0].menuItemList)
   routes = routes.concat(routesMainMenu)

   let routesFooterMenu = setupRouter(response.data[0].menuFooterList[0].menuItemList)
   routes = routes.concat(routesFooterMenu)

   let routesMiniFooterMenu = setupRouter(response.data[0].menuMiniFooterList[0].menuItemList)
   routes = routes.concat(routesMiniFooterMenu)

   routes = routes.concat(staticRedirects)

   routes.push(
      {
         path: "/cerca",
         name: "Cerca",
         params: true,
         component: Ricerca,
         props: route => ({ query: route.query.q }),
         meta: {
            breadcrumb: "ricerca", // For breadcrumbs in navbar
            type: "ricerca",
            layout: LayoutDefault,
         },
      },
      {
         path: '/ufficio/:slug',
         name: "ufficio",
         params: true,
         props: route => ({
            slug: route.params.slug,
            tipo: 'ufficio',
            tipoContenuto: 'ufficio'
         }),
         component: () => import('@/views/post.vue'),
         meta: {
            breadcrumb: 'home',
            type: "post",
            layout: LayoutDefault,
         },
      },
      {
         path: '/preview/:type/:id',
         name: 'preview',
         params: true,
         component: () => import('@/views/preview.vue'),
         meta: {
            breadcrumb: "",
            preview: true,
            layout: LayoutArticle
         }
      },
      {
         path: '/:pathMatch(.*)*',
         name: 'not-found',
         component: NotFound,
         meta: {
            breadcrumb: "404 Non Esiste",
            layout: LayoutDefault,
         }
      });

   store.commit("setMastHeadMenu", response.data[0].menuHeaderList[0].menuItemList);
   store.commit("setMainMenu", response.data[0].menuList[0].menuItemList);
   store.commit("setFooterMenu", response.data[0].menuFooterList[0].menuItemList);
   store.commit("setMiniFooterMenu", response.data[0].menuMiniFooterList[0].menuItemList);
   store.commit('setLayout', response.data[0].bloccoPaginaList);

   const router1 = createRouter({
      history: createWebHistory(),
      mode: 'history', // Add this
      hashbang: false,
      base: process.env.BASE_URL,
      routes,
      linkActiveClass: "active", // active class for non-exact links.
      linkExactActiveClass: "active", // active class for *exact* links.
      scrollBehavior() {
         return { top: 0, left: 0 }
      }
   });
   app.use(router1)
   app.config.devtools = false
   app.config.debug = false
   app.config.silent = true
});

// apply global filters
app.config.globalProperties.$filters = {
   fixStrLength(str, maxLength) {
      if (str.length > maxLength) {
         return str.substring(0, maxLength) + '...';
      }
      else {
         return str;
      }
   },
   formatNumber(str, intero = false) {
      if ((str == null && intero) || (str == 0 && intero)) { return '0'; }
      if ((str == null && !intero) || (str == 0 && !intero)) { return '0,00'; }

      return str.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
   },
   formatCurrency(str) {
      if (str == null) { return '0,00'; }
      return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(str);
   },

   getMonthName(monthInt) {
      const monthNames = ["gen", "feb", "mar", "apr", "mag", "giu", "lug", "ago", "set", "ott", "nov", "dic"];
      return monthNames[monthInt];
   },
   getFullMonthName(monthInt) {
      const monthNames = ["gennaio", "febbraio", "marzo", "aprile", "maggio", "giugno", "luglio", "agosto", "settembre", "ottobre", "novembre", "dicembre"];
      return monthNames[monthInt];
   },
   getMonthDay: function (val) {
      var d = new Date(val);
      return (d.getDate() <= 9 ? '0' : '') + d.getDate()
   },
   getMonthDate: function (val) {
      var d = new Date(val);
      return this.getMonthName(d.getMonth())
   },
   getYearDate: function (val) {
      var d = new Date(val);
      return d.getFullYear()
   },
   getMonthYearDate: function (val) {
      let d = new Date(val);
      return this.getFullMonthName(d.getMonth()) + ' ' + d.getFullYear()
   },
   getFullDate: function (val) {
      let d = new Date(val);
      var strDay = (d.getDate() <= 9 ? '0' : '') + d.getDate();
      return strDay + ' ' + this.getFullMonthName(d.getMonth()) + ' ' + d.getFullYear()
   },
   getDate(dateMod, format) {
      const days = ["domenica", "lunedì", "martedì", "mercoledì", "giovedì", "venerdì", "sabato"];

      var d = new Date(dateMod);
      format = (format != '' ? format : 'd-m-y');

      if (format === 'd-m') {
         return d.getDate() + ' ' + this.getMonthName(d.getMonth());
      }
      else if (format === 'y-m-d') {
         return d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
      }
      else if (format === 'd-m-y t') {
         if (d.getHours() == '0' && d.getMinutes() == '0') {
            return this.getMonthDay(d.getDay()) + ' ' + d.getDate() + ' ' + this.getMonthName(d.getMonth()) + ' ' + d.getFullYear();
         } else {
            return days[d.getDay()] + ' ' + d.getDate() + ' ' + this.getMonthName(d.getMonth()) + ' ' + d.getFullYear() + ' alle ' + (d.getHours() > 9 ? d.getHours() : '0' + d.getHours()) + ':' + (d.getMinutes() > 9 ? d.getMinutes() : '0' + d.getMinutes());
         }
      }
      else {
         return d.getDate() + ' ' + this.getFullMonthName(d.getMonth()) + ' ' + d.getFullYear();
      }
   },
   beforeLast(value, delimiter) {
      value = value || ''

      if (delimiter === '') {
         return value
      }

      const substrings = value.split(delimiter)

      return substrings.length === 1
         ? value // delimiter is not part of the string
         : substrings.slice(0, -1).join(delimiter)
   }
}
app.mount('#app');
